<template>
  <div class="list-file-selected">
    <div
      v-for="(file, index) in proofOrigins"
      :key="index"
      class="file"
    >
      <div class="name-file text-truncate">
        <v-icon
          size="20"
          class="mr-2"
        >
          mdi-file-outline
        </v-icon>
        {{ file.name }}
      </div>
      <button-remove-file
        class="button-remove-file"
        :index-file="index"
        @removeFile="removeFile"
      />
    </div>
  </div>
</template>

<script>
import ButtonRemoveFile from '@/components/buttons/proof_origin/ButtonRemoveFile.vue';

export default {
  name: 'ListFilesSelected',

  components: { ButtonRemoveFile },

  props: {
    proofOrigins: {
      type: Array,
      default: () => [],
    },
  },

  methods: {
    removeFile(index) {
      this.$emit('removeFile', index);
    },
  },
};
</script>

<style lang="scss" scoped>
.list-file-selected {
  max-width: 100%;
  max-height: 300px;
  overflow-y: auto;

  .file {
    border-radius: 6px;
    margin: 20px 10px 20px 0px;
    background: $files_backgrond;
    height: 84px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .name-file {
      padding-left: 32px;
      font-size: 1.1em;
      font-weight: 400;
      width: calc(100% - 336px);
      color: $color_text_card_kind;
    }

    .button-remove-file {
      margin-right: 32px;
    }
  }

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $color_primary;
    border-radius: 20px;
  }
}
</style>
