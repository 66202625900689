<template>
  <div class="stepper-nfe">
    <back-button
      :text="$t('components.stepper_credit.reuse_nfes')"
      @click="backToHomeScreen"
    />

    <div class="head-step">
      <base-step
        v-for="items in allSteps"
        :key="items.step"
        :content-step="items"
        :selected-step="items.step === getStepNfe"
        @goToStep="goToStep"
      />
    </div>

    <v-stepper
      v-model="getStepNfe"
      flat
      tile
      class="stepper"
    >
      <v-stepper-items class="pa-0">
        <v-stepper-content
          class="pa-0"
          step="1"
        >
          <card-nfe @sendNfeFiles="sendNfeFiles" />
        </v-stepper-content>

        <v-stepper-content
          class="pa-0"
          step="2"
        >
          <card-proof-origin @sendProofOriginFiles="sendProofOriginFiles" />
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>

    <generic-confirmation-dialog
      v-model="dialog"
      :component="component"
    />
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import BaseStep from '@/components/headers/nfe/BaseStep.vue';
import BackButton from '@/components/buttons/BackButton.vue';
import CardNfe from '@/components/cards/nfe/CardNfe.vue';
import CardProofOrigin from '@/components/cards/proof_origin/CardProofOrigin.vue';
import GenericConfirmationDialog from '@/components/dialogs/GenericConfirmationDialog.vue';

export default {
  name: 'StepperNfe',
  components: {
    BaseStep,
    CardNfe,
    BackButton,
    CardProofOrigin,
    GenericConfirmationDialog,
  },

  data() {
    return {
      dialog: false,
      allSteps: [
        { step: 1, title: this.$t('components.stepper_credit.steps.step1') },
        { step: 2, title: this.$t('components.stepper_credit.steps.step2') },
      ],
    };
  },

  computed: {
    ...mapGetters([
      'getStepNfe',
      'getBusiness',
      'getProofOriginSuccess',
      'getNfeSendSuccess',
    ]),

    component() {
      return this.getStepNfe === 1 ? 'nfe' : 'proof_origin';
    },
  },

  watch: {
    getProofOriginSuccess(value) {
      if (value) this.dialog = true;
    },

    getNfeSendSuccess(value) {
      if (value) this.dialog = true;
    },

    dialog(value) {
      if (!value) this.resetStateOfSuccess();
    },
  },

  methods: {
    ...mapActions([
      'setStepsNfe',
      'setNfeFileToSend',
      'resetStateOfSuccess',
      'initLoading',
      'sendFilesNfes',
      'sendFilesProofOrigins',
    ]),

    goToStep(step) {
      this.setStepsNfe(step);
    },

    sendNfeFiles(nfes) {
      const formData = new FormData();

      const { id } = this.getBusiness;
      formData.append('business_id', id);

      nfes.forEach((xml) => formData.append('nfe_files[]', xml.file));
      this.sendFilesNfes(formData);
    },

    sendProofOriginFiles(formData) {
      const { id } = this.getBusiness;
      formData.append('business_id', id);

      this.sendFilesProofOrigins(formData);
    },

    backToHomeScreen() {
      this.$router.push({ name: 'home' });
    },
  },
};
</script>
<style lang="scss" scoped>
.stepper-nfe {
  width: 100%;

  .stepper {
    background: transparent;
  }

  .head-step {
    display: flex;
    margin-top: 48px;
  }
}
</style>
