<template>
  <div class="xml-component">
    <label
      v-if="!filesSelected"
      class="button-content"
      for="xml"
    >
      <img src="@/assets/icons/ic_upload.svg">
      <div
        class="text-upload ml-2"
        @click="clearFiles"
      >
        <div class="title-text-upload">
          {{ $t('components.xml_uploader.label.select_file') }}
        </div>
        <div class="mt-2 subtitle-text-upload">
          {{ $t('components.xml_uploader.label.xml') }}
        </div>
      </div>
    </label>
    <v-file-input
      id="xml"
      v-model="inputXml"
      accept=".xml"
      hide-input
      prepend-icon=""
      multiple
    />
    <div
      v-if="filesSelected"
      class="card-files"
    >
      <p class="documents">
        {{ $t('components.stepper_business.steps.documents') }}
      </p>
      <span class="files-selected-length">
        {{ `${files.length} ${$t('components.card_file.file')}` }}
      </span>
      <div
        class="files-selected mt-6"
      >
        <div>
          <div class="box-file">
            <file-nfe
              v-for="(nfe, key) in files"
              :key="key"
              :name="nfe.file.name"
              :error="nfe.error"
              :index="key"
              :status="nfe.status"
              @remove="removeNfeFile"
            />
          </div>
          <label
            for="xml"
            class="plus-file"
            @click="clearFiles"
          >
            <v-icon
              size="24"
              color="primary"
              class="mr-4"
            >
              mdi-plus
            </v-icon>
            {{ $t('components.card_file.add_file') }}
          </label>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import FileNfe from '@/components/inputs/nfe/FileNfe.vue';

export default {
  name: 'XmlUploader',
  components: {
    FileNfe,
  },
  props: {
    files: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      inputXml: [],
      errors: [],
    };
  },
  computed: {
    filesSelected() {
      return this.files.length > 0;
    },
  },
  watch: {
    inputXml(value) {
      if (value.length > 0) this.mountFiles(value);
    },
  },
  methods: {
    mountFiles(xmls) {
      xmls.forEach((file, index) => {
        if (!this.validateFile(file, index)) this.$emit('setNfeFiles', { file });
      });

      if (this.errors.length > 0) {
        this.$store.dispatch('addErrorMessage', this.$t('errors.file_duplicate', { file: this.errors.toString() }));
        this.errors = [];
      }

      this.clearFiles();
    },

    validateFile(file, index) {
      const request = new XMLHttpRequest();

      request.open('GET', URL.createObjectURL(file), false);
      request.send();

      const xml = request.responseXML;
      const invalidFile = !xml || !xml.getElementsByTagName('infNFe')[0];

      if (invalidFile) return this.errors.push(` ${this.$t('errors.file_invalid')} ${index}`);
      return invalidFile;
    },

    clearFiles() {
      this.inputXml = [];
    },

    removeNfeFile(index) {
      this.$emit('removeNfeFile', index);
    },
  },
};
</script>
<style lang="scss" scoped>
  .xml-component {
    .button-content {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      border-style: dashed;
      border-color: $step_disabled;
      height: 160px;
      margin: auto;
      padding: 20px;
      cursor: pointer;

      .text-upload {
        color: $step_disabled;
        display: flex;
        flex-direction: column;
        cursor: pointer;

        .title-text-upload {
          font-size: 1em;
          font-weight: bold;
        }

        .subtitle-text-upload {
          font-size: 0.8em;
          font-weight: bold;
        }
      }
    }

    .card-files {

      .documents {
        color: $subtitle_text_dashboard;
        font-size: 1.2em;
        font-weight: 600;
      }

      .files-selected-length, .plus-file {
        font-weight: 400;
        font-size: 1em;
        color: $subtitle_text_dashboard;
      }

      .files-selected {
        max-width: 100%;
        background-color: $files_backgrond;
        padding: 10px;
        border-radius: 10px;

        .box-file {
          max-height: 222px;
          overflow-y: scroll;
          overflow-x: hidden;
          margin-top: 10px;
        }

        ::-webkit-scrollbar {
          width: 4px;
        }

        ::-webkit-scrollbar-track {
          background: transparent;
        }

        ::-webkit-scrollbar-thumb {
          background: $color_primary;
        }

        .plus-file {
          display: flex;
          flex-direction: row;
          margin-top: 26px;
          justify-content: center;
          text-decoration: none;
          font-size: 1.2em;
          color: $color_primary;
          cursor: pointer;
        }
      }
    }

    .label-xml {
      color: $label_text_upload_file;
      font-size: 0.875em;
      margin-left: 12px;
    }
  }
</style>
