<template>
  <div class="base-file-uploader">
    <box-select-file
      v-if="!filesSelected"
      v-model="files"
      :file-type="fileType"
      :component-id="componentId"
    />

    <list-files-selected
      v-else
      :proof-origins="proofOrigins"
      @removeFile="removeFile"
    />
  </div>
</template>
<script>
import BoxSelectFile from '@/components/cards/proof_origin/uploader/BoxSelectFile.vue';
import ListFilesSelected from '@/components/cards/proof_origin/uploader/ListFilesSelected.vue';

export default {
  name: 'BaseFileUploader',

  components: { BoxSelectFile, ListFilesSelected },

  props: {
    fileType: {
      type: String,
      default: '.xml',
    },
    proofOrigins: {
      type: Array,
      default: () => [],
    },
    filesSelected: {
      type: Boolean,
      default: false,
    },
    componentId: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      files: [],
    };
  },

  watch: {
    files(value) {
      if (value.length > 0) this.$emit('addFiles', value);
    },
  },

  methods: {
    removeFile(index) {
      this.$emit('removeFile', index);
    },
  },
};
</script>
