<template>
  <div class="card-nfe">
    <div class="nfe">
      <div class="header-card-send-nfe">
        <div class="title-card">
          {{ $t('components.stepper_credit.card_nfe.title_card') }}
        </div>
        <v-radio-group
          v-model="selectedTypeFile"
          row
        >
          <v-radio
            color="primary"
            value="xml"
            :label="$t('components.stepper_credit.card_nfe.labels.radio_xml')"
          />

          <v-radio
            color="primary"
            value="zip"
            :label="$t('components.stepper_credit.card_nfe.labels.radio_zip')"
          />
        </v-radio-group>
      </div>
      <div class="content-card">
        <div class="component-file">
          <xml-uploader
            v-if="sendXml"
            :files="files"
            @setNfeFiles="setNfeFiles"
            @removeNfeFile="removeNfeFile"
          />
          <zip-uploader
            v-else
            :files="files"
            @setZipFile="setZipFile"
            @removeNfeFile="removeNfeFile"
          />
        </div>
      </div>
    </div>
    <div class="button-component">
      <rectoplus-button
        class="button-send"
        :text="$t('components.stepper_credit.button')"
        :disabled="statusButton"
        @click="sendNfeFiles"
      />
    </div>
  </div>
</template>
<script>
import XmlUploader from '@/components/cards/nfe/XmlUploader.vue';
import ZipUploader from '@/components/cards/nfe/ZipUploader.vue';
import RectoplusButton from '@/components/buttons/RectoplusButton.vue';

export default {
  name: 'CardNfe',
  components: {
    XmlUploader,
    ZipUploader,
    RectoplusButton,
  },
  props: {
    currentStep: {
      type: Number,
      require: true,
      default: 2,
    },
  },
  data() {
    return {
      selectedTypeFile: 'xml',
      files: [],
    };
  },
  computed: {
    sendXml() {
      return this.selectedTypeFile === 'xml';
    },
    statusButton() {
      return !this.files.length > 0;
    },
  },
  watch: {
    selectedTypeFile() {
      this.cleanFiles();
    },
  },
  methods: {
    nextStep() {
      this.$emit('nextStep');
    },

    sendNfeFiles() {
      this.$emit('sendNfeFiles', this.files);
      this.files = [];
    },

    setNfeFiles(files) {
      this.files.push(files);
    },

    setZipFile(file) {
      this.cleanFiles();
      this.files.push(file);
    },

    cleanFiles() {
      this.files = [];
    },

    removeNfeFile(index) {
      this.files.splice(index, 1);
    },
  },
};
</script>
<style lang="scss" scoped>
  .card-nfe {
    .nfe {
      background: $card_nfe;
      border-radius: 0 10px 10px 10px;
      padding: 40px;

      .header-card-send-nfe {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .title-card {
          color: $title_text_dashboard;
          font-size: 1.5em;
          font-weight: bold;
        }
      }

      .content-card {
        display: flex;
        flex-direction: column;
        margin-top: 12px;

        .component-file {
          margin-top: 6px;
          flex-direction: row;
          align-items: center;
        }
      }
    }

    .button-component {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
      margin: 25px 0px 12px 0px;

      .button-send {
        height: 70px;
        display: flex;
        width: 520px;
        align-content: center;
      }

      .button-next {
        margin-left: 20px;
        height: 70px;
        width: 200px;
        display: flex;
        align-content: center;
      }

      .button-send-proof {
        margin-left: 20px;
        height: 70px;
        width: 520px;
        display: flex;
        align-content: center;
      }
    }
  }
</style>
