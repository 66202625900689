<template>
  <div
    class="box-select-file"
  >
    <label
      :for="componentId"
      class="button-content"
    >
      <img src="@/assets/icons/ic_upload.svg">
      <div class="text-upload ml-2">
        <div class="title-text-upload">
          {{ $t('components.xml_uploader.label.select_file') }}
        </div>
        <div class="mt-2 subtitle-text-upload">
          {{ $t('components.xml_uploader.label.type_file', { type: fileType }) }}
        </div>
      </div>
    </label>

    <v-file-input
      :id="componentId"
      v-model="files"
      :accept="fileType"
      hide-input
      prepend-icon=""
      multiple
    />
  </div>
</template>

<script>
export default {
  name: 'BoxSelectFile',

  model: {
    prop: 'files',
    event: 'change',
  },

  props: {
    fileType: {
      type: String,
      default: '.xml',
    },
    componentId: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      files: [],
    };
  },

  watch: {
    files() {
      this.$emit('change', this.files);
    },
  },
};
</script>

<style lang="scss" scoped>
.box-select-file {
  .button-content {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-style: dashed;
    border-color: $step_disabled;
    height: 160px;
    margin: auto;
    padding: 20px;

    .text-upload {
      color: $step_disabled;
      font-weight: bold;
      display: flex;
      flex-direction: column;
      cursor: pointer;

      .title-text-upload {
        font-size: 1.2em;
      }

      .subtitle-text-upload {
        font-size: 0.8em;
      }
    }
  }
}
</style>
