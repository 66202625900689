<template>
  <div class="zip-uploader">
    <label
      :class="classToSelectFile"
      for="zip"
    >
      <img
        :src="img"
        :class="classImageToFileSelected"
      >
      <span
        v-if="fileSelected"
        class="text-file-selected"
      >
        {{ files[0].file.name }}
      </span>
      <div
        v-else
        class="text-upload ml-2"
        @click="clearFiles"
      >
        <div class="title-text-upload">
          {{ $t('components.zip_uploader.label.select_file') }}
        </div>
        <div class="mt-2 subtitle-text-upload">
          {{ $t('components.zip_uploader.label.zip') }}
        </div>
      </div>
    </label>
    <v-file-input
      id="zip"
      v-model="file"
      accept=".zip"
      hide-input
      prepend-icon=""
    />
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import imageUpload from '@/assets/icons/ic_upload.svg';
import imageUploadSelect from '@/assets/icons/ic_upload_select.svg';

export default {
  name: 'ZipUploader',
  props: {
    files: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      file: null,
      wasProcessed: false,
    };
  },
  computed: {
    ...mapGetters([
      'getBusiness',
    ]),

    fileSelected() {
      return this.files.length > 0;
    },

    classToSelectFile() {
      return `button-select-file ${this.fileSelected ? 'selected' : ''}`;
    },

    classImageToFileSelected() {
      return this.fileSelected ? 'img-select-file' : '';
    },

    img() {
      return this.fileSelected ? imageUploadSelect : imageUpload;
    },
  },
  watch: {
    file(value) {
      if (value) {
        this.$emit('setZipFile', { file: value });
      }
    },
  },
  methods: {
    clearFiles() {
      this.file = null;
    },
  },
};
</script>
<style lang="scss" scoped>
  .zip-uploader {
    .button-select-file {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      border-style: dashed;
      border-color: $step_disabled;
      height: 160px;
      margin: auto;
      padding: 20px;

      .img-select-file {
        width: 26px;
      }

      .text-file-selected {
        color: $color_primary;
        font-size: 1.2rem;
        font-weight: bold;
      }

      .text-upload {
        color: $step_disabled;
        display: flex;
        flex-direction: column;
        cursor: pointer;

        .title-text-upload {
          font-size: 1em;
          font-weight: bold;
        }

        .subtitle-text-upload {
          font-size: 0.8em;
          font-weight: bold;
        }
      }

      &.selected {
        border-color: $color_primary;
      }
    }
  }
</style>
