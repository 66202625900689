<template>
  <button
    class="button-remove-file"
    @click="remove()"
  >
    <v-icon
      size="20"
      color="ic_invalid"
    >
      mdi-delete
    </v-icon>
    {{ $t('components.buttons.remove_file') }}
  </button>
</template>
<script>
export default {
  name: 'ButtonRemoveFile',
  props: {
    text: {
      type: String,
      default: '',
    },
    indexFile: {
      type: Number,
      default: null,
    },
  },
  methods: {
    remove() {
      this.$emit('removeFile', this.indexFile);
    },
  },
};
</script>
<style lang="scss" scoped>
.button-remove-file {
  border: 2px solid $message_invalid;
  background: transparent;
  width: 250px;
  height: 48px;
  border-radius: 10px;
  color: $message_invalid;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  font-weight: bold;
  cursor: pointer;
}
</style>
