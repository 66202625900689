<template>
  <div :class="[styleFile, 'box-files']">
    <div class="file">
      <div class="title-file">
        <v-icon
          size="20"
          :color="colorIcon"
          class="mr-2"
        >
          mdi-file-outline
        </v-icon>
        {{ name }}
      </div>
      <div v-if="error">
        {{ error }}
      </div>
      <v-btn
        class="btn"
        icon
        @click="removeFile()"
      >
        <v-icon
          small
          color="black"
        >
          mdi-delete
        </v-icon>
      </v-btn>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    status: {
      type: String,
      default: '',
    },
    error: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    index: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      styleFile: '',
      colorIcon: 'black',
    };
  },
  watch: {
    status(value) {
      if (value === 'invalid') {
        this.styleFile = 'message-invalid';
        this.colorIcon = 'ic_invalid';
      }
      if (value === 'pending') {
        this.styleFile = 'message-pending';
        this.colorIcon = 'ic_pending';
      }
      if (value === 'active') {
        this.styleFile = 'message-active';
        this.colorIcon = 'ic_active';
      }
    },
  },
  methods: {
    removeFile() {
      this.$emit('remove', this.index);
    },
  },
};
</script>
<style lang="scss" scoped>
  .box-files {
    .file {
      margin-bottom: 20px;
      display: flex;
      padding-right: 4px;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: space-between;
      .btn {
        grid-column: end;
      }
      .title-file {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;
        margin-right: 28px;
      }
    }
  }
  .message-invalid {
    color: $message_invalid;
  }
  .message-pending {
    color: $message_pending;
  }
  .message-active {
    color: $message_active;
  }
  .not-error {
    color: $subtitle_text_dashboard;
  }

</style>
