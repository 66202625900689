<template>
  <div class="base-card-upload-proof-origin">
    <div class="title-card">
      <div class="text-title">
        {{ cardTitle }}
      </div>
      <div
        v-if="filesSelected"
        class="button-title"
      >
        <button-select-file
          class="button-add-file"
          :component-id="componentId"
          :file-type="fileType"
          @addFiles="mountFiles"
        />
      </div>
    </div>

    <base-file-uploader
      :proof-origins="files"
      :file-type="fileType"
      :files-selected="filesSelected"
      :component-id="secondComponentId"
      @removeFile="removeFile"
      @addFiles="mountFiles"
    />
  </div>
</template>
<script>
import { mapActions } from 'vuex';
import ButtonSelectFile from '@/components/buttons/proof_origin/ButtonSelectFile.vue';
import BaseFileUploader from '@/components/cards/proof_origin/uploader/BaseFileUploader.vue';

export default {
  name: 'BaseCardUploadProofOrigin',

  components: {
    ButtonSelectFile,
    BaseFileUploader,
  },

  model: {
    prop: 'proofFile',
    event: 'change',
  },

  props: {
    cardTitle: {
      type: String,
      default: '',
    },
    fileType: {
      type: String,
      default: '',
    },
    proofFile: {
      type: Array,
      default: () => [],
    },
    component: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      files: [],
      errors: [],
    };
  },

  computed: {
    filesSelected() {
      return this.files.length > 0;
    },

    componentId() {
      return `${this.component}-file`;
    },

    secondComponentId() {
      return `${this.component}-file-two`;
    },
  },

  watch: {
    files(value) {
      if (value.length > 0) this.$emit('change', value);
    },

    proofFile(value) {
      this.files = value;
    },
  },

  methods: {
    ...mapActions([
      'addErrorMessage',
    ]),

    mountFiles(files) {
      files.forEach((file) => {
        if (!this.validateFile(file)) {
          this.files.push(file);
        }
      });

      if (this.errors.length > 0) {
        this.addErrorMessage(this.$t('errors.files_invalids', { files: this.errors.toString() }));
        this.errors = [];
      }
    },

    removeFile(index) {
      this.files.splice(index, 1);
    },

    validateFile(file) {
      if (this.fileType !== '.xml') return false;
      if (file.type !== 'text/xml') return true;
      const request = new XMLHttpRequest();

      request.open('GET', URL.createObjectURL(file), false);
      request.send();

      const xml = request.responseXML;
      const invalidFile = !xml || !xml.getElementsByTagName('infNFe')[0];

      if (invalidFile) this.errors.push(` ${file.name}`);
      return invalidFile;
    },
  },
};
</script>
<style lang="scss" scoped>
.base-card-upload-proof-origin {
  margin-top: 32px;
  background: $card_nfe;
  border-radius: 10px;
  padding: 40px;

  .title-card {
    margin-bottom: 36px;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;

    .text-title {
      color: $title_text_dashboard;
      font-size: 1.5em;
      font-weight: bold;
    }

    .button-title {
      margin-right: 42px;
    }
  }

  &:first-child {
    margin-top: 0;
    border-top-left-radius: 0;
  }
}
</style>
