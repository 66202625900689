<template>
  <div class="card-proof-origin">
    <!-- Vamos validar a ideia com o cliente para apagar esse componente -->
    <!-- <files-for-proof-origin /> -->

    <base-card-upload-proof-origin
      v-model="entryInvoice"
      :card-title="$t('components.stepper_credit.card_entry_invoice.title')"
      component="entry-invoice"
      file-type=".xml, .zip"
    />

    <base-card-upload-proof-origin
      v-model="recieved"
      :card-title="$t('components.stepper_credit.card_recieved.title')"
      component="recieved"
      file-type=".pdf, image/*"
    />

    <base-card-upload-proof-origin
      v-model="scaleVoucher"
      :card-title="$t('components.stepper_credit.card_scale_voucher.title')"
      component="scale-voucher"
      file-type=".pdf, image/*"
    />

    <base-card-upload-proof-origin
      v-model="contract"
      :card-title="$t('components.stepper_credit.card_contract.title')"
      component="contract"
      file-type=".pdf, .xlsx, image/*"
    />

    <!-- Cliente solicitou que essa funcionalidade fosse desabilida por enquanto  -->
    <!-- <card-auto-proof /> -->

    <div class="button-component">
      <rectoplus-button
        class="button-send-proof"
        :text="$t('components.stepper_credit.button')"
        :disabled="!hasFiles"
        @click="sendFiles"
      />
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import RectoplusButton from '@/components/buttons/RectoplusButton.vue';
import BaseCardUploadProofOrigin from '@/components/cards/proof_origin/BaseCardUploadProofOrigin.vue';

export default {
  name: 'CardProofOrigin',
  components: {
    RectoplusButton,
    BaseCardUploadProofOrigin,
  },
  data() {
    return {
      files: [],
      entryInvoice: [],
      recieved: [],
      scaleVoucher: [],
      contract: [],
    };
  },

  computed: {
    ...mapGetters([
      'getBusiness',
      'getProofOriginSuccess',
    ]),

    hasFiles() {
      return this.entryInvoice.length > 0 || this.recieved.length > 0
        || this.scaleVoucher.length > 0 || this.contract.length > 0;
    },
  },

  watch: {
    getProofOriginSuccess(value) {
      if (value) {
        this.files = [];
        this.entryInvoice = [];
        this.recieved = [];
        this.scaleVoucher = [];
        this.contract = [];
      }
    },
  },

  methods: {
    ...mapActions([
      'sendFilesProofOrigins',
    ]),

    sendFiles() {
      const formData = new FormData();

      this.entryInvoice.forEach((file) => formData.append('entry_invoice[]', file));
      this.recieved.forEach((file) => formData.append('receipt[]', file));
      this.scaleVoucher.forEach((file) => formData.append('scale_voucher[]', file));
      this.contract.forEach((file) => formData.append('contract[]', file));

      this.$emit('sendProofOriginFiles', formData);
    },
  },
};
</script>
<style lang="scss" scoped>
.card-proof-origin {
  .button-component {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: nowrap;
    justify-content: center;
    margin: 25px 0px 12px 0px;

    .button-send-proof {
      margin-left: 20px;
      height: 70px;
      width: 520px;
      display: flex;
      align-content: center;
    }
  }
}
</style>
