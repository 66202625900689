<template>
  <div class="send-nfe">
    <stepper-nfe />
  </div>
</template>
<script>
import StepperNfe from '@/components/stepps/StepperNfe.vue';

export default {
  name: 'SendNfe',
  components: {
    StepperNfe,
  },
};
</script>
<style lang="scss" scoped>
  .send-nfe {
    background: $dashboard_background;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    padding: 2em 2em 2em 16em;
  }

  @media (max-width: 960px) {
    .send-nfe {
      padding-left: 60px;
    }
  }
</style>
