<template>
  <div>
    <label
      :for="componentId"
      class="button-file-proof-origin"
    >
      <img
        class="img-plus"
        src="@/assets/icons/ic_plus.svg"
      >

      {{ $t('components.buttons.add_file') }}
    </label>

    <v-file-input
      :id="componentId"
      v-model="files"
      hide-input
      :accept="fileType"
      prepend-icon=""
      multiple
    />
  </div>
</template>
<script>
export default {
  name: 'ButtonSelectFile',

  props: {
    text: {
      type: String,
      default: '',
    },

    componentId: {
      type: String,
      default: '',
    },

    fileType: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      files: [],
    };
  },

  watch: {
    files(value) {
      if (value.length > 0) this.$emit('addFiles', value);
    },
  },
};
</script>
<style lang="scss" scoped>
.button-file-proof-origin {
  background: $color_primary;
  width: 250px;
  height: 48px;
  border-radius: 10px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  font-weight: bold;
  cursor: pointer;
}
</style>
