<template>
  <div
    :class="['base-step', classStep]"
    @click="goToStep()"
  >
    <div>
      {{ contentStep.title }}
    </div>
  </div>
</template>
<script>
export default {
  name: 'BaseStep',

  props: {
    selectedStep: {
      type: Boolean,
      require: true,
    },

    contentStep: {
      type: Object,
      require: true,
      default: () => {},
    },
  },

  computed: {
    classStep() {
      return this.selectedStep ? 'step' : 'step-disable';
    },
  },

  methods: {
    goToStep() {
      this.$emit('goToStep', this.contentStep.step);
    },
  },
};
</script>
<style lang="scss" scoped>
  .base-step{
    display: flex;
    flex-direction: row;
    align-items: center;
    font-weight: 600;
    flex-wrap: wrap;
    font-size: 1.2em;
    font-weight: bold;
    height: 100px;
    cursor: pointer;
    border-radius: 10px 10px 0 0;
    background: $card_nfe;
    padding: 0 14px;

    .chip-step {
      display: flex;
      align-items: center;
      justify-content: center;
      border: solid $color_dark;
      width: 40px;
      height: 40px;
      border-radius: 100%;
      margin-right: 16px;
      font-weight: bold;
    }

    &:first-child {
      margin-right: 24px;
    }

    &.step-disable {
      opacity: 0.5;
    }

  }
</style>
